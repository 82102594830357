import styled from 'styled-components'
import { theme } from '../theme'
import { HomepagePStrong } from './Homepage/Type'

export const ProductIcon = styled.div`
  margin: 0 auto ${props => props.theme.spacing.snug} auto;
  width: 80px;
  height: 80px;
`

export const Product = styled.div`
  grid-column: span 2;
  margin-bottom: ${theme.spacing.loose};

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    margin-bottom: 0;
    grid-column: span 2;
    grid-row-start: 2;
  }
`

export const ProductText = styled(HomepagePStrong)`
  text-align: center;
`
