import styled from 'styled-components'
import { displayFont, textFont } from '../../styles/typography'

export const HomepageH2 = styled.h2`
  ${displayFont('large', 'bold')};
`

export const HomepageH3 = styled.h3`
  ${displayFont('small', 'bold')};
`

export const HomepageH4 = styled.h4`
  ${displayFont('small', 'bold')};
`

export const HomepagePStrong = styled.p`
  ${textFont('bodyLarge', 'bold')};
`

export const HomepageP = styled.p`
  ${textFont('bodyLarge')};
`

export const HomepageBlockquote = styled.blockquote`
  ${displayFont('medium', 'bold')};
  font-style: italic;
  text-align: center;

  ::before,
  ::after {
    display: block;
    font-size: 100px;
    color: ${props => props.theme.typography.color.orange};
  }

  ::before {
    content: '“';
    text-align: left;
  }

  ::after {
    content: '”';
    text-align: right;
  }
`

export const HomepageCite = styled.cite`
  display: block;
  ${displayFont('small')};
  font-style: normal;
`
