import React, { FC, DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import styled from 'styled-components'
import { Grid } from './Grid'
import { displayFont } from '../styles/typography'
import { theme } from '../theme'

export const LogoGroupH3 = styled.h3`
  grid-column: 1 / -1;
  ${displayFont('small', 'bold')};
  text-align: center;
`

type ImgProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

const LogoContainer = styled.div`
  grid-column: span 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: ${props => props.theme.borderRadius.large};
  padding: ${props => props.theme.spacing.default}
    ${props => props.theme.spacing.loose};
  box-shadow: ${theme.elevation.content};
  height: 112px;

  img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

export function Logo({ className, ...props }: ImgProps): JSX.Element {
  return (
    <LogoContainer className={className}>
      <img {...props} />
    </LogoContainer>
  )
}

const ImageGroup = styled(Grid)`
  justify-items: stretch;
  align-items: stretch;
  row-gap: ${props => props.theme.spacing.loose};

  h2 {
    grid-column: span 4;
    text-align: center;
    ${displayFont('small', 'bold')};

    @media (min-width: ${props => props.theme.breakpoint.desktop}) {
      row-gap: ${props => props.theme.spacing.extraLoose};
      grid-column: span 8;
    }
  }
`

interface ImageGroupProps {
  // https://styled-components.com/docs/basics#styling-any-component
  className?: string
}

export const LogoGroup: FC<ImageGroupProps> = ({ className, children }) => {
  return <ImageGroup className={className}>{children}</ImageGroup>
}
