import React, { FC } from 'react'
import styled from 'styled-components'

// -- Layout --
import { theme } from '../theme'
import { PageLayout } from '../layouts/PageLayout'
import { LargeCallToAction } from '../modules/LargeCallToAction'

// -- Components --
import { Title } from '../components/Title'
import { Grid } from '../components/Grid'
import { Button } from '../components/Button'
import * as Hero from '../components/Hero'
import {
  HomepageH2,
  HomepageH3,
  HomepageH4,
  HomepageP,
  HomepageBlockquote,
  HomepageCite
} from '../components/Homepage/Type'
import { Logo, LogoGroup } from '../components/LogoGroup'
import {
  LargeBullet,
  LargeBulletContent,
  LargeBulletH3,
  LargeBulletH4,
  LargeBulletImage,
  LargeBulletP
} from '../components/LargeBullet'
import { Product, ProductIcon, ProductText } from '../components/Product'
import { BulletList, BulletListItem } from '../components/BulletList'
import { BubbleImage } from '../components/BubbleImage'

// -- Images --
import heroHomeSrc from '../images/hero/hero-home.jpg'
import imgBubble1Src from '../images/home/img-bubble-home-1.jpg'
import imgBubble2Src from '../images/home/img-bubble-home-2.jpg'
import imgBubbleSmall1Src from '../images/home/img-bubble-small-home-1.png'
import imgBubbleSmall2Src from '../images/home/img-bubble-small-home-2.jpg'

// Icons
import sustainabilityGoalsSrc from '../images/icons/icon-hp-sustainability-goals.svg'
import buildingEnergyRatingsSrc from '../images/icons/icon-hp-building-energy-ratings.svg'
import occupantExperienceSrc from '../images/icons/icon-hp-occupant-experience.svg'
import operationalProductivitySrc from '../images/icons/icon-hp-operational-productivity.svg'
import moreHvacSrc from '../images/icons/icon-hp-more-hvac.svg'
import analyticsSrc from '../images/icons/icon-hp-analytics.svg'
import teamSrc from '../images/icons/icon-hp-team.svg'
import safeHandsSrc from '../images/icons/icon-hp-safe-hands.svg'
import contactUsImg from '../images/industries/universities/contact-us.png'
import logoSrcCrown from '../images/logos/logo-crown@2x.png'
import logoSrcRmit from '../images/logos/logo-rmit-university@2x.png'
import logoSrcWoolworths from '../images/logos/logo-woolworths@2x.png'
import logoSrcPennState from '../images/logos/logo-penn-state@2x.png'
import { displayFont, textFont } from '../styles/typography'

const BackgroundColor = styled.div<{ color: string }>`
  background-color: ${props => props.color ?? 'transparent'};
`

const H2 = styled(HomepageH2)`
  grid-column: 1 / -1;
  text-align: center;
`

const H3 = styled(HomepageH3)`
  grid-column: 1 / -1;
  text-align: center;
  ${displayFont('small', 'normal')};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    grid-column: 2 / -2;
  }
`

const Blockquote = styled(HomepageBlockquote)`
  grid-column: 1 / -1;
  text-align: center;
  ${displayFont('small', 'normal')};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    grid-column: 2 / -2;
  }
`

const Cite = styled(HomepageCite)`
  margin-top: ${props => props.theme.spacing.loose};
  ${textFont('body', 'semiBold')};
`

const CustomerImage = styled.div`
  grid-column: 1/-1;
  justify-content: center;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  box-shadow: ${theme.elevation.content};
  margin-bottom: ${theme.spacing.default};

  img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    grid-column: 2 / span 2;
    width: 240px;
    height: 240px;

    img {
      width: 240px;
      height: 240px;
      margin: 0 auto;
    }
  }
`

const CustomerContent = styled.div`
  grid-column: 1/-1;

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    grid-column: 4 / span 4;
  }
`

// const CTA = styled(LargeCallToAction)`
//   margin-bottom: ${props => props.theme.spacing.ultraLoose};
//   @media screen and (min-width: ${props => props.theme.breakpoint.desktop}) {
//     margin-bottom: calc(${props => props.theme.spacing.ultraLoose} * 2);
//   }
// `

const IndexPage: FC = () => {
  return (
    <PageLayout>
      <Title title="Smart Building Analytics - Bueno" />

      <Hero.Wrapper src={heroHomeSrc}>
        <Hero.LayoutContent>
          <Hero.LayoutHeading>
            <Hero.Heading>
              Superior Insights.
              <br />
              Smart Decisions.
            </Hero.Heading>
            <Hero.Subheading>
              We help teams harness the power of{' '}
              <strong>Smart Building Analytics</strong> to realize a more{' '}
              <strong>Sustainable Future</strong>
            </Hero.Subheading>
          </Hero.LayoutHeading>
          <div style={{ marginTop: `${theme.spacing.ultraLoose}` }}>
            <LogoGroup>
              <Logo src={logoSrcCrown} alt="Crown Casino" />
              <Logo src={logoSrcRmit} alt="RMIT University" />
              <Logo src={logoSrcWoolworths} alt="Woolworths" />
              <Logo src={logoSrcPennState} alt="Penn State University" />
            </LogoGroup>
          </div>
        </Hero.LayoutContent>
      </Hero.Wrapper>

      <Grid
        style={{
          marginBottom: `${theme.spacing.extraLoose}`,
          marginTop: '56px'
        }}
      >
        <H3
          style={{
            fontWeight: `${theme.typography.weight.bold}`,
            marginBottom: `${theme.spacing.tight}`
          }}
        >
          Connect people with meaningful insights to enable smarter
          decision-making. Create healthy buildings by:
        </H3>
      </Grid>

      <Grid style={{ marginBottom: '112px' }}>
        <Product>
          <ProductIcon>
            <img src={sustainabilityGoalsSrc} />
          </ProductIcon>
          <ProductText>Achieving sustainability goals</ProductText>
        </Product>

        <Product>
          <ProductIcon>
            <img src={buildingEnergyRatingsSrc} />
          </ProductIcon>
          <ProductText>Maximising building energy ratings</ProductText>
        </Product>

        <Product>
          <ProductIcon>
            <img src={occupantExperienceSrc} />
          </ProductIcon>
          <ProductText>Enhancing occupant experience</ProductText>
        </Product>

        <Product>
          <ProductIcon>
            <img src={operationalProductivitySrc} />
          </ProductIcon>
          <ProductText>Supercharge operational productivity</ProductText>
        </Product>
      </Grid>

      <H2 style={{ marginBottom: theme.spacing.ultraLoose }}>Why Bueno</H2>

      <Grid style={{ alignItems: 'start', marginBottom: '112px' }}>
        <LargeBullet style={{ gridColumn: 'span 4' }}>
          <LargeBulletImage src={moreHvacSrc} />
          <LargeBulletContent>
            <LargeBulletH4>More than just HVAC</LargeBulletH4>
            <LargeBulletP>
              View all stand-alone systems through our centralized analytics
              software. Connect Water, Utilities, Elevators, Refrigeration, BAS,
              Weather, and more...
            </LargeBulletP>
          </LargeBulletContent>
        </LargeBullet>

        <LargeBullet style={{ gridColumn: 'span 4' }}>
          <LargeBulletImage src={analyticsSrc} />
          <LargeBulletContent>
            <LargeBulletH4>Analytics at scale</LargeBulletH4>
            <LargeBulletP>
              Proven at scale of &gt;1,000 buildings per client, enjoy complete
              visibility of your building portfolio through a centralized
              approach
            </LargeBulletP>
          </LargeBulletContent>
        </LargeBullet>

        <LargeBullet style={{ gridColumn: 'span 4' }}>
          <LargeBulletImage src={teamSrc} />
          <LargeBulletContent>
            <LargeBulletH4>
              Your passionate team of trusted industry experts
            </LargeBulletH4>
            <LargeBulletP>
              Don’t take it from us, our customers believe the best part about
              us is our team
            </LargeBulletP>
          </LargeBulletContent>
        </LargeBullet>

        <LargeBullet style={{ gridColumn: 'span 4' }}>
          <LargeBulletImage src={safeHandsSrc} />
          <LargeBulletContent>
            <LargeBulletH4>You’re in safe hands</LargeBulletH4>
            <LargeBulletP>
              Secured and trusted by enterprise, governments & defense around
              the world
            </LargeBulletP>
          </LargeBulletContent>
        </LargeBullet>
        <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
          <Button to="/solutions">View Solutions</Button>
        </div>
      </Grid>

      <div style={{ marginBottom: '112px' }}>
        <H2 style={{ marginBottom: `${theme.spacing.tight}` }}>
          Customer first
        </H2>
        <Grid style={{ marginBottom: `${theme.spacing.default}` }}>
          <H3 style={{ marginBottom: `${theme.spacing.loose}` }}>
            We strive to build healthy relationships by identifying your needs
            and providing the best possible experience
          </H3>
        </Grid>

        <Grid style={{ marginBottom: `${theme.spacing.loose}` }}>
          <CustomerImage>
            <img src={imgBubbleSmall1Src} />
          </CustomerImage>
          <CustomerContent>
            <HomepageP>
              Congratulations Dexus, a customer of Bueno, for winning the
              coveted 2021 Global Real Estate Sustainability Benchmark (GRESB)
              Overall Regional Sector Leaders award.
            </HomepageP>
          </CustomerContent>
        </Grid>

        {/* HACK: Woah man */}
        <Grid style={{ direction: 'ltr' }}>
          <CustomerImage>
            <img src={imgBubbleSmall2Src} />
          </CustomerImage>
          <CustomerContent style={{ direction: 'ltr' }}>
            <HomepageH4 style={{ marginBottom: `${theme.spacing.tight}` }}>
              Quick Customer Facts{' '}
            </HomepageH4>
            <BulletList>
              <BulletListItem>
                Used by 6 out of 10 of Australia’s largest REITs
              </BulletListItem>
              <BulletListItem>
                Used by the 20th largest retailer in the world
              </BulletListItem>
              <BulletListItem>
                Used by the largest casino in the southern hemisphere
              </BulletListItem>
              <BulletListItem>98% Customer retention rate</BulletListItem>
            </BulletList>
          </CustomerContent>
        </Grid>
      </div>

      <BackgroundColor
        color="#fff"
        style={{ padding: '64px 0', boxShadow: `${theme.elevation.content}` }}
      >
        <Grid>
          <Blockquote>
            "In our first 18 months of partnership, we identified savings of
            $780k which helped us increase NABERS ratings across our buildings
            through 2,800MWh of energy reduction and optimized operational
            labor. A great deal of these impressive achievements is a result of
            Bueno and their team of industry experts.”
            <Cite>
              — Paul Vandervlis, General Manager Facilities Services, Investa
            </Cite>
          </Blockquote>
        </Grid>
      </BackgroundColor>

      <div style={{ padding: '64px 0' }}>
        <BubbleImage
          src={imgBubble1Src}
          textPosition="center"
          imagePosition="left"
          maxHeight={640}
        >
          <LargeBullet>
            <LargeBulletContent>
              <LargeBulletH3>FMCG & Groceries</LargeBulletH3>
              <LargeBulletP>
                Developed for hundreds, if not thousands of stores, and with
                refrigeration in mind, discover how we can optimize your
                operations and enhance the customer experience.
              </LargeBulletP>
              <Button
                to="/industries/grocery"
                style={{ marginTop: `${theme.spacing.loose}` }}
              >
                Learn More
              </Button>
            </LargeBulletContent>
          </LargeBullet>
        </BubbleImage>

        <BubbleImage
          src={imgBubble2Src}
          textPosition="center"
          imagePosition="right"
          maxHeight={640}
        >
          <LargeBullet>
            <LargeBulletContent>
              <LargeBulletH3>Universities</LargeBulletH3>
              <LargeBulletP>
                Deliver a better quality of service to your students and staff
                by optimizing your campus's performance whilst achieving
                net-zero targets.
              </LargeBulletP>
              <Button
                to="/industries/universities"
                style={{ marginTop: `${theme.spacing.loose}` }}
              >
                Learn More
              </Button>
            </LargeBulletContent>
          </LargeBullet>
        </BubbleImage>
      </div>

      {/* <div style={{ marginBottom: '64px' }}>
        <CTA
          title="Trusted & passionate team of experts"
          body="We have the most experienced and trusted team coupled with the market leading software to support your building optimisation journey.
        Learn more about the product features and functionality, and our services here."
          image={contactUsImg}
          callToAction={
            <Button to="#">
              Become A Leader
            </Button>
          }
        />
      </div> */}
    </PageLayout>
  )
}

export default IndexPage
